import React, {
  ChangeEvent,
  FC,
  ForwardRefRenderFunction,
  PropsWithChildren,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useState,
} from 'react'
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import cx from 'classnames'
import CoreText from './CoreText'
import styles from './CoreInput.module.scss'
import { useFormattedPhoneInput } from 'components/Core/useFormattedPhoneInput'
import ExpandMore from 'svgs/expand-more'

const currencyMaskOptions = {
  prefix: '$',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ',',
  allowDecimal: true,
  decimalSymbol: '.',
  decimalLimit: 2,
  allowNegative: false,
  allowLeadingZeroes: true,
}

const currencyMask = createNumberMask(currencyMaskOptions)

interface WrapperProps {
  className?: string
  hint?: string
  onChange?: (value: string) => void
  kind?: 'enabled' | 'alert' | 'positive' | 'disabled'
  id?: string
  label?: string
  borderType?: 'bounded' | 'boundless'
  elementSize?: 'l' | 'xl'
}

const Wrapper: FC<PropsWithChildren<WrapperProps>> = ({
  hint,
  className,
  kind = 'enabled',
  children,
  id,
  label,
  borderType = 'bounded',
  elementSize = 'xl',
}) => {
  return (
    <>
      <div className={cx(styles.wrapper, kind, borderType, elementSize, className)}>
        <div className={styles.inputWrapper}>
          {children}
          <label htmlFor={id}>{label}</label>
        </div>
        {hint && (
          <CoreText.Paragraph size="s" color="tertiaryBlack" weight="light" className={styles.hint}>
            {hint}
          </CoreText.Paragraph>
        )}
      </div>
    </>
  )
}

type PhoneInputProps = { onChange?: (value: string) => void } & Omit<JSX.IntrinsicElements['input'], 'onChange'>

const RenderPhoneInput: ForwardRefRenderFunction<HTMLInputElement, PhoneInputProps> = (
  { onChange, value, ...props },
  forwardedRef
) => {
  const { formattedValue, ref, handlePhoneNumberChanged } = useFormattedPhoneInput(value?.toString(), onChange)

  useImperativeHandle(forwardedRef, () => ref.current as HTMLInputElement)

  return (
    <input ref={ref} className={styles.input} onChange={handlePhoneNumberChanged} {...props} value={formattedValue} />
  )
}

const PhoneInput = forwardRef(RenderPhoneInput)

export type InputProps = WrapperProps & Omit<JSX.IntrinsicElements['input'], 'onChange'>

const Input: ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  { value, hint, kind, className, onChange, id, label, placeholder, borderType, elementSize, ...props },
  ref
) => {
  const [phoneValue, setPhoneValue] = useState<string>()

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (onChange) onChange(e.target.value)
    },
    [onChange]
  )

  const handlePhoneChanged = useCallback(
    (value: string) => {
      setPhoneValue(value)
      if (onChange) onChange(value)
    },
    [onChange]
  )

  const handlePhoneReset = useCallback(() => {
    setPhoneValue('')
    if (onChange) onChange('undefined')
  }, [onChange])

  return (
    <Wrapper
      hint={hint}
      label={label}
      borderType={borderType}
      kind={kind}
      elementSize={elementSize}
      id={id}
      className={cx(styles.wrapper, className)}
    >
      {props.type == 'tel' ? (
        <PhoneInput
          id={id}
          placeholder={placeholder || label}
          className={styles.input}
          onChange={handlePhoneChanged}
          value={value || phoneValue}
          onReset={handlePhoneReset}
          {...props}
          ref={ref}
        />
      ) : (
        <input
          id={id}
          placeholder={placeholder || label}
          value={value}
          className={styles.input}
          onChange={handleChange}
          {...props}
          ref={ref}
        />
      )}
    </Wrapper>
  )
}

export type MoneyInputProps = WrapperProps & Omit<JSX.IntrinsicElements['input'], 'onChange'>

const MoneyInput: ForwardRefRenderFunction<HTMLInputElement, MoneyInputProps> = (
  { value, hint, kind, className, onChange, id, label, placeholder, borderType, elementSize, ...props },
  ref
) => {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange?.(event.target.value.replace(/[$,]/g, ''))
    },
    [onChange]
  )

  return (
    <Wrapper
      hint={hint}
      label={label}
      borderType={borderType}
      kind={kind}
      elementSize={elementSize}
      id={id}
      className={cx(styles.wrapper, className)}
    >
      <MaskedInput
        mask={currencyMask}
        id={id}
        placeholder={placeholder || label}
        value={value}
        className={styles.input}
        onChange={handleChange}
        inputMode="numeric"
        {...props}
        ref={ref}
      />
    </Wrapper>
  )
}

type TextAreaProps = WrapperProps & Omit<JSX.IntrinsicElements['textarea'], 'onChange'>

const TextArea: ForwardRefRenderFunction<HTMLTextAreaElement, TextAreaProps> = (
  { hint, kind, className, onChange, rows: rowsProp, label, elementSize, placeholder, borderType, id, ...props },
  ref
) => {
  const rows = rowsProp || 4

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      if (onChange) onChange(e.target.value)
    },
    [onChange]
  )

  return (
    <Wrapper
      hint={hint}
      label={label}
      id={id}
      kind={kind}
      borderType={borderType}
      elementSize={elementSize}
      className={cx(styles.wrapper, styles.ta, className)}
    >
      <textarea
        id={id}
        placeholder={placeholder || label}
        className={styles.input}
        rows={rows}
        onChange={handleChange}
        ref={ref}
        {...props}
      />
    </Wrapper>
  )
}

type SelectProps = WrapperProps & { allowBlank?: boolean } & Omit<JSX.IntrinsicElements['select'], 'onChange'>

const Select: ForwardRefRenderFunction<HTMLSelectElement, SelectProps> = (
  { hint, kind, className, onChange, label, placeholder, id, allowBlank, children, borderType, elementSize, ...props },
  ref
) => {
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      if (onChange) onChange(e.target.value)
    },
    [onChange]
  )

  return (
    <Wrapper
      hint={hint}
      label={label}
      id={id}
      kind={kind}
      borderType={borderType}
      elementSize={elementSize}
      className={cx(styles.wrapper, className)}
    >
      <ExpandMore className={styles.accessory} />
      <select id={id} className={styles.input} onChange={handleChange} {...props} ref={ref}>
        {(allowBlank || !!placeholder) && (
          <option value="" disabled={!allowBlank} hidden={!allowBlank}>
            {allowBlank ? '' : placeholder}
          </option>
        )}
        {children}
      </select>
    </Wrapper>
  )
}

const CoreInput = {
  Text: forwardRef(Input),
  Money: forwardRef(MoneyInput),
  TextArea: forwardRef(TextArea),
  Select: forwardRef(Select),
}

export default CoreInput
